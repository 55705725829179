import React, { useState, useEffect } from 'react';
import { getLootList} from '../services/getLootList';
import RosterList from './RosterList'
import LootByDate from './LootByDate'
import { compareDates } from '../libs/utils';

const listStyle = {
    width: '90%',
    padding: '10px',
}
const rosterStyle = {
    width: '9%'
}
const pageStyle = {
    display: 'flex',
    'justifyContent': 'center',
    backgroundColor: 'lightgrey'
}

const mapToDates = (items) => {
    let map = {};
    items.forEach( (item, index) => {
        if (!map.hasOwnProperty(item.date)) map[item.date] = [];
        map[item.date].push(item);
    });
    map = Object.keys(map).sort(compareDates).reduce(
        (obj, key) => {
            obj[key] = map[key];
            return obj;
        }, {}
    );
    return map;
}

const genRosterFromItems = (items) => {
    let roster = [];
    items.forEach( (item) => {
        if (!roster.includes(item.player)) roster.push(item.player);
    });
    
    return roster.map( (item) => { return {player: item, selected: false}});
}

const  LootList = (props) => {
    //const [list, setList] = useState([]);
    const [dateMap, setDateMap] = useState({});
    const [roster, setRoster] = useState([]);
    const [onlyMs, showOnlyMs] = useState(false);
    const toggleShowMs = () => {
        showOnlyMs(!onlyMs);
    }
    const updateRosterMember = (memberName) => {
        let _roster = [...roster];
        //get index of player
        let _index = -1;
        for (let i=0; i<_roster.length;i++){
            if (_roster[i].player === memberName) _index = i;
        }
        if (_index === -1) return;
        let _player = {
            ..._roster[_index],
            selected : !_roster[_index].selected
        };
        _roster[_index] = _player;
        setRoster([..._roster]);
    }
    useEffect(() => {
        let mounted = true;
        getLootList().then( items => {
            if (mounted) {
                //setList(items);
                setDateMap(mapToDates(items));
                setRoster(genRosterFromItems(items));
            }
        })
        return () => mounted = false;
    }, []);
    return (
        <div style={pageStyle}>
            <div style={listStyle}>
                <LootByDate list={dateMap} roster={roster} onlyMs={onlyMs}/>
            </div>
            <div style={rosterStyle}>
                <RosterList roster={roster} updateMember={updateRosterMember} toggleMs={toggleShowMs} isMs={onlyMs}/>
            </div>
        </div>
    )
}

export default LootList;