
const genStyle = (item) => {
    return {
        backgroundColor: item.selected ? "green" : "white"
    };
}

const showMsStyle = (ms) => {
    return {
        paddingTop: "10px",
        paddingBottom: "20px",
        backgroundColor: ms ? "green" : "white"
    }
}

const RosterList = (props) => {
    return (
        <div>
            <div style={showMsStyle(props.isMs)} onClick={() => props.toggleMs()}>
                show only ms
            </div>
        {props.roster.map( (item, index) => 
            <div key={item.player} style={genStyle(item)} onClick={() => props.updateMember(item.player)}>
                {item.player}
            </div>
        )}
        </div>
    )
}

export default RosterList;