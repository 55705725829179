import React from 'react';

const colorMap = {
    DRUID: 'rgb(255,124,10)',
    HUNTER: 'rgb(170,211,114)',
    MAGE: 'rgb(63,199,235)',
    PALADIN: 'rgb(244,140,186)',
    PRIEST: 'rgb(255,255,255)',
    ROGUE: 'rgb(255,244,104)',
    SHAMAN: 'rgb(0,112,221)',
    WARLOCK: 'rgb(135,136,238)',
    WARRIOR: 'rgb(198,155,109)',
}

const bgColorMap = {
    "1": 'rgba(0,255,0, 0.5)',
    "2": `rgba(184,134,11, 0.5)`,
    "3": `rgba(176,224,230, 0.5)`
}

const genStyle = (data) => {
    return {
        border: `5px solid ${colorMap[data.class]}`,
        backgroundColor: bgColorMap[(data.responseId.toString())],
        padding: '10px',
        margin: '10px'
    }
}

const _parentStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: "nowrap"
}

const _childStyle = {
    fontSize: "13px",
    width: "100%"
}

const LootItemFull = (props) => {
    return (
        <div style={genStyle(props.value)}>
            <div style={_parentStyle}>
                <div style={_childStyle}>
                    Name: {props.value.player}<br />
                    Item: {props.value.item}<br />
                    Note: {props.value.note}<br />
                    Boss: {props.value.boss}<br />
                    Had: {props.value.itemReplaced1}
                </div>
            </div>
        </div>
    )
}

export default LootItemFull;