
import { compareDates } from '../libs/utils';


function compareObj(_a, _b){
    return compareDates(_a.date, _b.date);
}

export function getLootList() {
    /*
    return new Promise( (resolve,reject) => {
        resolve([{"SK":1, "player": "player", "item":"item", "class":"ROGUE", 'responseId': 3}])
    })
    */
    console.log("Getting Loot List");
    return fetch('https://3mx7g5c0y3.execute-api.us-east-1.amazonaws.com/predev/loot')
        .then(data => {
            return data.json()}
        )
        .then(json => {
            let filteredList = json.items.filter ( item => compareDates(item.date, "01/06/21") <= 0);
            filteredList.sort(compareObj);
            return filteredList;
        })
        .catch(e => {
            return [];
        })
    
}