
export function compareDates(_a , _b) {
    let a = {year: parseInt(_a.split("/")[2]), month: parseInt(_a.split("/")[1]), day: parseInt(_a.split("/")[0])};
    let b = {year: parseInt(_b.split("/")[2]), month: parseInt(_b.split("/")[1]), day: parseInt(_b.split("/")[0])};
    if (a.year > b.year) return -1;
    if (a.year === b.year && a.month > b.month) return -1;
    if (a.year === b.year && a.month === b.month && a.day > b.day) return -1;
    if (a.year === b.year && a.month === b.month && a.day === b.day) return 0;
    else return 1;
}
