
import LootItemFull from './LootItemFull'
import {useEffect, useState} from 'react'
const dateStyle = {
    justifyContent: "center",
    textAlign: 'center',
    fontSize: '20px'
}

const dateSegmentParent = {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: "nowrap"
}

const dateSegmentChild = {

    width: "50%"
}

const mapToPlayers = (loot, msOnly) => {
    console.log(msOnly)
    let mappedObject = {};
    for (const [date, lootArray] of Object.entries(loot)) {
        mappedObject[date] = {};
        lootArray.forEach((lootPiece) => {
            if (msOnly && lootPiece.responseId !== 1) return;
            if (!mappedObject[date].hasOwnProperty(lootPiece.player)) mappedObject[date][lootPiece.player] = [];
            mappedObject[date][lootPiece.player].push(lootPiece);
        });
    }
    return mappedObject;
}

const mapRoaster = (roster) => {
    return roster.filter( (item) => item.selected).map( (item) => item.player);
}

const dateHasItemForPlayerOfSelectedRoster = (date, roster) => {
    let r = false;
    for (let i = 0; i < roster.length; i++){
        if (date.hasOwnProperty(roster[i])) r = true;
    }
    return r;
}
const LootBydate = (props) => {
    const [lootList, setLootList] = useState(props.list);
    const [selectedMember, setSelectedMember] = useState([])
    useEffect(() => {
        setLootList(mapToPlayers(props.list, props.onlyMs));
    }, [props.list, props.onlyMs])
    useEffect(() => {
        setSelectedMember(mapRoaster(props.roster));
    }, [props.roster]);
    return (
        <div>
        {
            selectedMember.length === 0 &&
            Object.keys(props.list).map( (item, index) => 
                <div key={item}>
                    <div style={dateStyle}>{item}</div>
                    {props.list[item].map( item => 
                        <LootItemFull key={item.SK} value={item} />
                    )}
                    
                </div>
            )
        }
        {selectedMember.length !== 0 && 
         Object.keys(lootList).map( (date, index) => 
            <div key={date}>
            { dateHasItemForPlayerOfSelectedRoster(lootList[date], selectedMember) === true &&
            <div style={dateStyle}>
                {date}
                <div style={dateSegmentParent}>
                {
                selectedMember.map( (player) => 
                    <div key={player} style={dateSegmentChild}>
                        {player}
                        {
                            lootList[date].hasOwnProperty(player) && 
                             <div>
                                {
                                lootList[date][player].map( (lootPiece) => 
                                    <LootItemFull key={lootPiece.SK} value={lootPiece} />
                                )
                                }
                            </div>
                        }
                    </div>
                )
                }
                </div>
            </div>
            }</div>
         )
            

        }
        </div>
    )
}

export default LootBydate;